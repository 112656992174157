import React from 'react';
import { PageContext } from 'kp';
import PropTypes from 'prop-types';

import { assignInitialProps } from 'src/lib/assignInitialProps';

import { fetchToken } from '@store/auth/thunk';
import { wrapper } from 'src/store/configureStore';

import { useAuthSyncContext } from '../context/AuthSyncContext';

export function withAuthSyncComponent(WrappedComponent) {
  const WithAuthSync = (props) => {
    useAuthSyncContext();

    return <WrappedComponent {...props} />;
  };

  WithAuthSync.getLayout = WrappedComponent.getLayout;
  WithAuthSync.getLayoutMobile = WrappedComponent.getLayoutMobile;

  WithAuthSync.propTypes = {
    token: PropTypes.string,
  };

  WithAuthSync.defaultProps = {
    token: '',
  };

  WithAuthSync.getInitialProps = wrapper.getInitialAppProps(
    (store) => async (ctx: PageContext) => {
      const { req } = ctx;
      if (req) {
        await store.dispatch(fetchToken());
      }

      const componentProps = await assignInitialProps(WrappedComponent, ctx);

      return { pageProps: {}, ...componentProps };
    }
  );

  return WithAuthSync;
}
