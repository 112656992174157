import { normalizeCapitalizeFirstLetter } from '@lib/normalizers/normalizeCapitalizeFirstLetter';

const GROUP = {
  2753: {
    title: () =>
      'Solarni paneli & solarne baterije i akumulatori za skladištenje energije',
  },
};

const CATEGORY = {
  25: {
    title: ({ groupName }) => `${groupName} - Iznajmljivanje`,
  },
  26: {
    title: ({ groupName }) => `${groupName} - Prodaja`,
  },
  2392: {
    title: ({ groupName, categoryName }) => `${groupName} - ${categoryName}`,
  },
};

type Params = {
  totalAdsCount: number;
  groupName: string;
  categoryId: number;
  categoryName: string;
  so?: string;
  keywords?: string;
  groupId?: string;
};

export const getGroupMetaTags = (params: Params) => {
  const {
    totalAdsCount,
    groupName,
    categoryId,
    categoryName,
    so,
    keywords,
    groupId,
  } = params;

  const groupMetaObj = {
    title: groupName,
    description: `${totalAdsCount} oglasa u grupi ${groupName}.`,
  };

  if (groupName === 'Ostalo') {
    groupMetaObj.title = `${groupName} - ${categoryName}`;
    groupMetaObj.description = `${totalAdsCount} oglasa u grupi ${groupName} iz kategorije ${categoryName}.`;
  }

  if (CATEGORY[categoryId]?.title) {
    groupMetaObj.title = CATEGORY[categoryId].title(params);
  }

  if (GROUP[groupId]?.title) {
    groupMetaObj.title = GROUP[groupId].title();
  }

  if (so) {
    groupMetaObj.title = [
      ...(keywords ? [normalizeCapitalizeFirstLetter(keywords)] : []),
      ...(categoryName ? [categoryName] : []),
      groupName,
    ].join(' - ');
  }

  return groupMetaObj;
};
