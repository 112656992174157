import React from 'react';
import { PageContext } from 'kp';

import isBotOrExtension from '@lib/isBotOrExtension';
import { assignInitialProps } from 'src/lib/assignInitialProps';

import {
  fetchFeatureFlags,
  fetchGeneralGroupedInfo,
} from '@store/generalStats/thunk';
import { setPageUrl } from '@store/meta/slice';
import { wrapper } from 'src/store/configureStore';

import { usePrefetchContext } from '../context/PrefetchContext';

export function withPrefetchComponent(WrappedComponent) {
  const WithPrefetch = (props) => {
    usePrefetchContext();
    return <WrappedComponent {...props} />;
  };

  WithPrefetch.getLayout = WrappedComponent.getLayout;
  WithPrefetch.getLayoutMobile = WrappedComponent.getLayoutMobile;

  WithPrefetch.getInitialProps = wrapper.getInitialAppProps(
    (store) => async (ctx: PageContext) => {
      const { req, res, asPath } = ctx;
      store.dispatch(setPageUrl(asPath));
      if (req) {
        await store.dispatch(fetchGeneralGroupedInfo());
        await store.dispatch(fetchFeatureFlags());

        if (isBotOrExtension(req) && asPath.includes('undefined')) {
          res.statusCode = 410;
        }
      }
      const componentProps = await assignInitialProps(WrappedComponent, ctx);

      return { pageProps: {}, ...componentProps };
    }
  );

  return WithPrefetch;
}
