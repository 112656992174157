const CATEGORY = {
  2013: {
    title: 'Polovni i novi Automobili',
  },
  1251: {
    title: 'Automobili | Auto Oprema',
  },
  591: {
    title: 'Automobili | Auto delovi i alati',
  },
  23: {
    title: 'Mobilni telefoni - Polovni i Novi',
  },
};

type Meta = {
  title: string;
  description: string;
  ogImage?: string;
};

export const getCategoryMetaTags = ({
  totalCategoryAdsCount,
  categoryName,
  categoryId,
  categoryAdClass,
}) => {
  const categoryMetaObj: Meta = {
    title: categoryName,
    description: `${totalCategoryAdsCount} oglasa u kategoriji ${categoryName}.`,
  };

  if (categoryAdClass === 'car') {
    categoryMetaObj.description = `${totalCategoryAdsCount} oglasa u kategoriji ${categoryName}.`;
  }

  if (categoryAdClass === 'job') {
    categoryMetaObj.title = `${categoryName} - Oglasi za posao Beograd, Novi Sad`;
    categoryMetaObj.description = `Preko ${totalCategoryAdsCount} oglasa za poslove na KupujemProdajem. Šta ćeš sledeće da radiš?`;
    categoryMetaObj.ogImage = '/static/images/meta/job.png';
  }

  if (CATEGORY[categoryId]?.title) {
    categoryMetaObj.title = CATEGORY[categoryId].title;
  }

  return categoryMetaObj;
};
