/* eslint-disable @typescript-eslint/no-unused-vars */
export function normalizeCapitalizeFirstLetter(
  value = '',
  previousValue = '',
  maxLength = null
): string {
  const firstLetterIndex = value.search(/[a-zA-Z]/);
  if (firstLetterIndex === -1) {
    return value;
  }
  return (
    value.slice(0, firstLetterIndex) +
    value.charAt(firstLetterIndex).toUpperCase() +
    value.slice(firstLetterIndex + 1)
  );
}
