import React from 'react';
import { NextSeo } from 'next-seo';

import { ROOT_URL } from 'src/lib/constants/env.constants';

import { SeoProps } from '@type/seo';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectUnreadNotification } from '@store/meStats/selector';

const Seo = ({
  title,
  description = null,
  openGraph = null,
  ignoreSuffix = false,
  canonical = '',
  nofollow = false,
  noindex = false,
}: SeoProps) => {
  const { messages } = useAppSelector(selectUnreadNotification);

  const shouldShowUnread = messages > 0;
  const prefix = shouldShowUnread ? `(${messages}) ` : ``;
  const suffix = ignoreSuffix ? '' : ' - KupujemProdajem';
  const formattedTitle = `${prefix}${title}${suffix}`;
  const ogTitle = `${title}${suffix}`;

  if (openGraph?.images) {
    openGraph.images = openGraph.images.map((image) => {
      const url = image.url.includes('http')
        ? image.url
        : `${ROOT_URL}${image.url}`;
      const alt = 'KupujemProdajem';

      return {
        url,
        alt,
      };
    });
  }

  const formattedOpenGraph = {
    ...openGraph,
    title: ogTitle,
  };

  return (
    <NextSeo
      title={formattedTitle}
      description={description}
      openGraph={formattedOpenGraph}
      canonical={canonical}
      nofollow={nofollow}
      noindex={noindex}
    />
  );
};

export default Seo;
