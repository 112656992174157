import isEmpty from 'lodash/isEmpty';

import { getCategoryMetaTags } from './getCategoryMetaTags';
import { getGroupMetaTags } from './getGroupMetaTags';
import { getSeoOverrideTitle } from './getSeoOverrideTitle';

const META_TAGS = {
  'ad-promo': {
    title: 'Promocija oglasa',
    description: 'Promocija oglasa za bržu prodaju',
  },

  mediakit: {
    title: 'Media Kit',
    ogImage: '/static/images/mediakit/media-kit-og-image-2024.png',
    description: 'Media Kit 2024',
  },

  'dopuna-kredita': {
    title: 'KP Kredit',
    description: 'Dopuna KP Kredita',
  },

  cenovnik: {
    title: 'Cenovnik',
    description: 'Cene promocije oglasa',
  },

  'uputstvo-za-banere': {
    title: 'Uputstvo za banere',
    description: 'Uputstvo za banere',
  },

  category: (params) => getCategoryMetaTags(params),

  allUserAds: ({ username }) => ({
    title: 'Svi oglasi korisnika',
    description: `Svi oglasi korisnika - ${username}.`,
  }),

  search: (params) => {
    const title = getSeoOverrideTitle('Rezultati pretrage', params);
    return {
      title,
    };
  },

  'search-category': ({ totalAdsCount, categoryName, so, keywords }) => {
    const title = getSeoOverrideTitle(categoryName, {
      so,
      keywords,
    });

    return {
      title,
      description: `${totalAdsCount} oglasa u kategoriji ${categoryName}.`,
    };
  },

  group: (params) => getGroupMetaTags(params),

  'group-job': ({ totalAdsCount, groupName }) => ({
    title: `${groupName} - Poslovi`,
    description: `Pogledajte preko ${totalAdsCount} oglasa za poslove u grupi ${groupName}.`,
    ogImage: '/static/images/meta/job.png',
  }),

  'group-car': ({ totalAdsCount, groupName }) => ({
    title: `${groupName} - Polovni Automobili`,
    description: `${totalAdsCount} oglasa u grupi ${groupName}.`,
  }),

  'search-newest': {
    title: 'Najnoviji oglasi',
    description: '',
  },

  ad: ({ adName, adDescription }) => ({
    title: adName,
    description: adDescription,
  }),

  'ad-job': ({ adName, adDescription }) => ({
    title: `${adName} - Poslovi`,
    description: adDescription,
  }),

  home: {
    title: 'KupujemProdajem',
    description: '',
    ignoreSuffix: true,
  },
};

export const getMetaTags = (page: string, variable = {}) =>
  isEmpty(variable) ? META_TAGS[page] : META_TAGS[page](variable);
