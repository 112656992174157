import { normalizeCapitalizeFirstLetter } from '@lib/normalizers/normalizeCapitalizeFirstLetter';

export const getSeoOverrideTitle = (text, params) => {
  const { so, keywords } = params;
  return !so
    ? text
    : [
        ...(keywords ? [normalizeCapitalizeFirstLetter(keywords)] : []),
        text,
      ].join(' - ');
};
