import { createSelector } from 'reselect';

import { selectIsUserLogged } from '../auth/selector';
import { meStatsSelector } from '../rootSelector';

import { MeStatsInitialState, UnreadNotification } from './type';

export const selectPrepaidBalance = createSelector(
  meStatsSelector,
  (slicedState: MeStatsInitialState) => {
    try {
      return slicedState.prepaid;
    } catch (e) {
      return '' as string;
    }
  }
);

export const selectPrepaidBalanceAsNumber = createSelector(
  meStatsSelector,
  (slicedState: MeStatsInitialState) => {
    try {
      const withoutLocales = slicedState.prepaid.replace('.', '');
      return Number(withoutLocales);
    } catch (e) {
      return 0 as number;
    }
  }
);

export const selectUnreadNotification = createSelector(
  meStatsSelector,
  (slicedState: MeStatsInitialState) => {
    try {
      return slicedState.unread;
    } catch (e) {
      return {} as UnreadNotification;
    }
  }
);

export const selectIsLoaded = createSelector(meStatsSelector, (slicedState) => {
  const isPrepaidLoaded = slicedState.isLoaded.prepaid === true;
  const isUnreadLoaded = slicedState.isLoaded.unread === true;

  return isPrepaidLoaded && isUnreadLoaded;
});

export const selectIsLoading = createSelector(
  meStatsSelector,
  (slicedState: MeStatsInitialState) => slicedState.isLoading
);

export const selectUserStats = createSelector(
  meStatsSelector,
  (slicedState: MeStatsInitialState) => slicedState.stats
);

export const selectUserAdsCount = createSelector(
  meStatsSelector,
  (slicedState: MeStatsInitialState) => slicedState.userAdsCount
);

export const selectIsUserSeenWelcomeScreen = createSelector(
  meStatsSelector,
  selectIsUserLogged,
  (slicedState: MeStatsInitialState, isUserLogged) => {
    if (!isUserLogged) {
      return true;
    }

    return slicedState.stats.isSeenWelcomeScreenMegatron;
  }
);

export const selectUserEmail = createSelector(
  meStatsSelector,
  selectUserStats,
  (slicedState: MeStatsInitialState) => slicedState.stats.email
);

export const selectUserHasKpWindow = createSelector(
  meStatsSelector,
  selectUserStats,
  (slicedState: MeStatsInitialState) => !!slicedState.stats.kpIzlogUrl
);

export const selectIsUserInLockedPeriod = createSelector(
  meStatsSelector,
  (slicedState: MeStatsInitialState) => slicedState.isUserInLockedPeriod
);

export const selectCourierOrder = createSelector(
  meStatsSelector,
  (slicedState: MeStatsInitialState) => slicedState.courierOrder
);

export const selectUserActivateAdsInfo = createSelector(
  meStatsSelector,
  (slicedState: MeStatsInitialState) => slicedState.activateAdsInfo
);
